/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "nextjs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nextjs",
    "aria-label": "nextjs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Next.js"), "\n", React.createElement(_components.p, null, "Configure your ", React.createElement(_components.a, {
    href: "https://nextjs.org/"
  }, "Next.js"), " project to import SVG as React components in your application."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save-dev @svgr/webpack\n# or use yarn\nyarn add --dev @svgr/webpack\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Using SVGR in Next.js is possible with ", React.createElement(_components.code, null, "@svgr/webpack"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "next.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  webpack(config) {\n    // Grab the existing rule that handles SVG imports\n    const fileLoaderRule = config.module.rules.find((rule) =>\n      rule.test?.test?.('.svg'),\n    )\n\n    config.module.rules.push(\n      // Reapply the existing rule, but only for svg imports ending in ?url\n      {\n        ...fileLoaderRule,\n        test: /\\.svg$/i,\n        resourceQuery: /url/, // *.svg?url\n      },\n      // Convert all other *.svg imports to React components\n      {\n        test: /\\.svg$/i,\n        issuer: fileLoaderRule.issuer,\n        resourceQuery: { not: [...fileLoaderRule.resourceQuery.not, /url/] }, // exclude if *.svg?url\n        use: ['@svgr/webpack'],\n      },\n    )\n\n    // Modify the file loader rule to ignore *.svg, since we have it handled now.\n    fileLoaderRule.exclude = /\\.svg$/i\n\n    return config\n  },\n\n  // ...other config\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Star from './star.svg'\n\nconst Example = () => (\n  <div>\n    <Star />\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "Or, using the classic (URL) import:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Image from 'next/image'\nimport starUrl from './star.svg?url'\n\nconst Example = () => (\n  <div>\n    <Image src={starUrl} />\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "Please refer to ", React.createElement(_components.a, {
    href: "/docs/webpack/"
  }, "SVGR webpack guide"), " for advanced use cases."), "\n", React.createElement(_components.h2, {
    id: "typescript",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#typescript",
    "aria-label": "typescript permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "TypeScript"), "\n", React.createElement(_components.p, null, "Using SVGR with TypeScript support."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Type decleration")), "\n", React.createElement(_components.p, null, "Add a custom type decleration file (e.g. ", React.createElement(_components.strong, null, "svgr.d.ts"), ") to the root of your repo."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "declare module '*.svg' {\n  import { FC, SVGProps } from 'react'\n  const content: FC<SVGProps<SVGElement>>\n  export default content\n}\n\ndeclare module '*.svg?url' {\n  const content: any\n  export default content\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "tsconfig.json")), "\n", React.createElement(_components.p, null, "Add the type decleration file to your tsconfig.json's ", React.createElement(_components.code, null, "include"), " array. ", React.createElement(_components.strong, null, "Ensure it's the first item.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"include\": [\n    \"svgr.d.ts\",\n    \"next-env.d.ts\",\n    \"**/*.ts\",\n    \"**/*.tsx\",\n    \".next/types/**/*.ts\"\n  ]\n  // ...other config\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
